import {
  QueryParamConfig,
  encodeNumericArray,
  decodeNumericArray,
  decodeArray,
} from "use-query-params";
import { parse as parseUuid } from "uuid";
import { encodeArray } from "use-query-params";
import { isNotNullOrUndefined } from "@utils/typeguards";

export const removeQueryParams = (url: string, params: string[]): string => {
  const urlObj = new URL(url);
  const paramsObj = new URLSearchParams(urlObj.search);
  params.forEach((param) => {
    paramsObj.delete(param);
  });
  let newUrl = urlObj.origin + urlObj.pathname;
  if (paramsObj.toString() != "") {
    newUrl = `${url}?${paramsObj.toString()}`;
  }
  return newUrl;
};

export const TruthyNumberArrayParam: QueryParamConfig<number[], number[]> = {
  encode: (array: number[]) => {
    return encodeNumericArray(array);
  },
  decode: (value: string | (string | null)[] | null | undefined) => {
    const decoded = decodeNumericArray(value);
    if (!decoded) {
      return [];
    }
    return decoded.filter(isNotNullOrUndefined);
  },
};

export const StringArrayParam: QueryParamConfig<string[], string[]> = {
  encode: (array: string[]) => {
    return encodeArray(array);
  },
  decode: (value: string | (string | null)[] | null | undefined) => {
    const decoded = decodeArray(value);
    if (!decoded) {
      return [];
    }
    return decoded.filter(isNotNullOrUndefined);
  },
};

export const UuidParam: QueryParamConfig<string, string | undefined> = {
  encode: (uuid: string) => {
    return uuid;
  },
  decode: (value: string | (string | null)[] | null | undefined) => {
    if (typeof value !== "string") {
      return undefined;
    }
    try {
      parseUuid(value);
      return value;
    } catch (e) {
      return undefined;
    }
  },
};
