import {
  UserType,
  GetPeopleInput,
  LocationGroupType,
} from "@src/types.generated";
import {
  StringArrayParam,
  TruthyNumberArrayParam,
} from "@src/utils/queryParams";
import { useMemo } from "react";
import {
  BooleanParam,
  createEnumArrayParam,
  DateTimeParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { FilterHookRet } from "../types";
import { FilterOption } from "../core/FilterDropdownComponent";
import useLocationGroups from "@src/hooks/useLocationGroups";
import LocationGroupFilterDropdown from "@src/components/people/filter/LocationGroupFilterDropdown";
import LocationFilterDropdown from "@src/components/people/filter/LocationFilterDropdown";
import RoleFilterDropdown from "@src/components/people/filter/RoleFilterDropdown";
import DepartmentFilterDropdown from "@src/components/people/filter/DepartmentFilterDropdown";
import UserTypeFilterDropdown from "@src/components/people/filter/UserTypeFilterDropdown";
import HasAppFilterDropdown from "@src/components/people/filter/HasAppFilterDropdown";
import IncludeDeactivatedFilterDropdown from "@src/components/people/filter/IncludeDeactivatedFilterDropdown";
import { Calendar } from "@src/ui/calendar";
import { DateTime } from "luxon";

export type PeopleFilterState = {
  start: Date | undefined;
  end: Date | undefined;
  searchValue: string | undefined;
  locationIds: number[];
  franchiseIds: string[];
  brandIds: string[];
  regionIds: string[];
  roleGroupIds: string[];
  roleIds: number[];
  hasUsedApp: boolean | undefined;
  userTypes: UserType[];
  includeDeactivated: boolean | undefined | null;
  lastActive: string | undefined;
};

export const EMPTY_PEOPLE_FILTERS: PeopleFilterState = {
  start: undefined,
  end: undefined,
  searchValue: undefined,
  locationIds: [],
  franchiseIds: [],
  brandIds: [],
  regionIds: [],
  roleIds: [],
  roleGroupIds: [],
  hasUsedApp: undefined,
  userTypes: [],
  includeDeactivated: undefined,
  lastActive: undefined,
};

const usePeopleFilterState = (args?: {
  excludeFilterTypes?: (keyof PeopleFilterState)[];
}): FilterHookRet<PeopleFilterState, GetPeopleInput> => {
  const [_filters, updateFilters] = useQueryParams({
    start: withDefault(DateTimeParam, undefined),
    end: withDefault(DateTimeParam, undefined),
    searchValue: withDefault(StringParam, undefined),
    locationIds: withDefault(TruthyNumberArrayParam, []),
    franchiseIds: withDefault(StringArrayParam, []),
    regionIds: withDefault(StringArrayParam, []),
    brandIds: withDefault(StringArrayParam, []),
    roleIds: withDefault(TruthyNumberArrayParam, []),
    roleGroupIds: withDefault(StringArrayParam, []),
    hasUsedApp: withDefault(BooleanParam, undefined),
    userTypes: withDefault(createEnumArrayParam(Object.values(UserType)), []),
    includeDeactivated: BooleanParam,
    lastActive: withDefault(StringParam, undefined),
  });
  const filters: PeopleFilterState = _filters;

  const graphqlInput: GetPeopleInput = useMemo(() => {
    const locationGroupIds = filters.brandIds.concat(
      filters.franchiseIds,
      filters.regionIds,
    );
    return {
      locationIds:
        filters.locationIds.length > 0
          ? { value: filters.locationIds }
          : undefined,
      roleIds:
        filters.roleIds.length > 0 ? { value: filters.roleIds } : undefined,
      hasUsedApp:
        filters.hasUsedApp !== undefined
          ? { value: filters.hasUsedApp }
          : undefined,
      includeDeactivated:
        filters.includeDeactivated !== undefined
          ? { value: !!filters.includeDeactivated }
          : undefined,
      userTypes:
        filters.userTypes.length > 0 ? { value: filters.userTypes } : undefined,
      roleGroupIds:
        filters.roleGroupIds.length > 0
          ? { value: filters.roleGroupIds }
          : undefined,
      locationGroupIds:
        locationGroupIds.length > 0 ? { value: locationGroupIds } : undefined,
      inactiveSince: filters.lastActive
        ? { value: filters.lastActive }
        : undefined,
    };
  }, [filters]);
  const { locationGroupTypes } = useLocationGroups();
  const options: FilterOption<PeopleFilterState>[] = [
    {
      key: "locationIds",
      label: "Locations",
      selectedCount: filters.locationIds.length,
      subMenuContent: (
        <LocationFilterDropdown
          maxHeight={280}
          locationIds={filters.locationIds}
          onChange={(locationIds) => updateFilters({ locationIds })}
        />
      ),
    },
    {
      key: "franchiseIds",
      label: "Franchises",
      selectedCount: filters.franchiseIds.length,
      subMenuContent: locationGroupTypes.includes(
        LocationGroupType.Franchise,
      ) ? (
        <LocationGroupFilterDropdown
          maxHeight={280}
          locationGroupIds={filters.franchiseIds}
          locationGroupType={LocationGroupType.Franchise}
          onChange={(franchiseIds) => updateFilters({ franchiseIds })}
        />
      ) : null,
    },
    {
      key: "brandIds",
      label: "Brands",
      selectedCount: filters.brandIds.length,
      subMenuContent: locationGroupTypes.includes(LocationGroupType.Brand) ? (
        <LocationGroupFilterDropdown
          maxHeight={280}
          locationGroupIds={filters.brandIds}
          locationGroupType={LocationGroupType.Brand}
          onChange={(brandIds) => updateFilters({ brandIds })}
        />
      ) : null,
    },
    {
      key: "regionIds",
      label: "Regions",
      selectedCount: filters.regionIds.length,
      subMenuContent: locationGroupTypes.includes(LocationGroupType.Region) ? (
        <LocationGroupFilterDropdown
          maxHeight={280}
          locationGroupIds={filters.regionIds}
          locationGroupType={LocationGroupType.Region}
          onChange={(regionIds) => updateFilters({ regionIds })}
        />
      ) : null,
    },
    {
      key: "roleIds",
      label: "Roles",
      selectedCount: filters.roleIds.length,
      subMenuContent: (
        <RoleFilterDropdown
          maxHeight={280}
          roleIds={filters.roleIds}
          onChange={(roleIds) => updateFilters({ roleIds })}
        />
      ),
    },
    {
      key: "roleGroupIds",
      label: "Departments",
      selectedCount: filters.roleGroupIds.length,
      subMenuContent: (
        <DepartmentFilterDropdown
          maxHeight={280}
          roleGroupIds={filters.roleGroupIds}
          onChange={(roleGroupIds) => updateFilters({ roleGroupIds })}
        />
      ),
    },
    {
      key: "userTypes",
      label: "User type",
      selectedCount: filters.userTypes.length,
      subMenuContent: (
        <UserTypeFilterDropdown
          userTypes={filters.userTypes}
          onChange={(userTypes) => updateFilters({ userTypes })}
        />
      ),
    },
    {
      key: "lastActive",
      label: "Last active before",
      selectedCount: filters.lastActive ? 1 : 0,
      subMenuContent: (
        <Calendar
          selected={
            filters.lastActive
              ? DateTime.fromISO(filters.lastActive).toJSDate()
              : undefined
          }
          mode="single"
          onSelect={(date: Date | undefined) => {
            updateFilters({
              lastActive: date ? DateTime.fromJSDate(date).toISO() : undefined,
            });
          }}
        />
      ),
    },
    {
      key: "hasUsedApp",
      label: "App downloaded",
      selectedCount: filters.hasUsedApp !== undefined ? 1 : 0,
      subMenuContent: (
        <HasAppFilterDropdown
          hasUsedApp={filters.hasUsedApp}
          onChange={(hasUsedApp) => updateFilters({ hasUsedApp })}
        />
      ),
    },
    {
      key: "includeDeactivated",
      label: "Deactivated",
      selectedCount: filters.includeDeactivated !== undefined ? 1 : 0,
      subMenuContent: (
        <IncludeDeactivatedFilterDropdown
          includeDeactivated={filters.includeDeactivated}
          onChange={(includeDeactivated) =>
            updateFilters({ includeDeactivated })
          }
        />
      ),
    },
  ];

  return {
    filters,
    updateFilters,
    options: options.filter(
      (option) => !args?.excludeFilterTypes?.includes(option.key),
    ),
    graphqlInput,
  };
};

export default usePeopleFilterState;
