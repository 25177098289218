import React, { useMemo } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "@src/ui/dropdown-menu";
import { Button } from "@src/ui/button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import ActiveFilterCount from "@src/components/ui/ActiveFilterCount";
import { useQueryParams } from "use-query-params";

export interface FilterOption<T> {
  key: keyof T;
  label: string;
  selectedCount: number;
  subMenuContent: React.ReactNode;
}

interface FilterComponentProps<T> {
  filterOptions: FilterOption<T>[];
  onClearFilterValues: Array<Record<string, unknown>>;
}

const FilterDropdownComponent = <T extends object>({
  filterOptions,
  onClearFilterValues,
}: FilterComponentProps<T>): JSX.Element => {
  const activeCount = useMemo(() => {
    return filterOptions.reduce((acc, option) => {
      return acc + option.selectedCount;
    }, 0);
  }, [filterOptions]);
  const updateFilters = useQueryParams({})[1];
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="h-8">
          <DeprecatedIcon type="filter" color={deprecatedTones.gray5} />
          Filters
          <ActiveFilterCount count={activeCount} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() => {
            updateFilters({
              ...onClearFilterValues.reduce((acc, x) => ({ ...acc, ...x }), {}),
            });
          }}
        >
          Clear all
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {filterOptions.map((option) => (
          <React.Fragment key={option.key.toString()}>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                {option.label}
                {option.selectedCount > 0 ? ` (${option.selectedCount})` : ""}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {option.subMenuContent}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default FilterDropdownComponent;
