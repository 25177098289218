import MultiSelectMenuContent, {
  Choice,
} from "@components/ui/multiSelect/MultiSelectMenuContent";
import useLocations from "@src/hooks/useLocations";
import React, { useMemo } from "react";

type Props = {
  maxHeight?: React.CSSProperties["maxHeight"];
  locationIds: number[];
  onChange: (locationIds: number[]) => void;
};

const LocationFilterDropdown: React.FC<Props> = ({
  maxHeight = 320,
  locationIds,
  onChange,
}) => {
  const { locations, loading: loadingLocations } = useLocations();
  const allChoices: Choice[] = useMemo(() => {
    const locChoices = locations.map((g) => ({
      label: g.name,
      value: g.id.toString(),
    }));
    return locChoices;
  }, [locations]);
  const selectedChoices: Choice[] = useMemo(() => {
    return allChoices.filter((c) => locationIds.includes(Number(c.value)));
  }, [allChoices, locationIds]);
  const onSelectedChoicesChange = (newChoices: Choice[]): void => {
    const locationIds = newChoices.map((nc) => Number(nc.value));
    onChange(locationIds);
  };
  return (
    <MultiSelectMenuContent
      allChoices={allChoices}
      selectedChoices={selectedChoices}
      onSelectedChoicesChange={onSelectedChoicesChange}
      loading={loadingLocations}
      maxMenuHeight={maxHeight}
    />
  );
};

export default LocationFilterDropdown;
