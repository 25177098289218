import MultiSelectMenuContent, {
  Choice,
} from "@components/ui/multiSelect/MultiSelectMenuContent";
import useRoleGroups from "@src/hooks/useRoleGroups";
import React, { useMemo } from "react";

type Props = {
  maxHeight?: React.CSSProperties["maxHeight"];
  roleGroupIds: string[];
  onChange: (roleIds: string[]) => void;
};

const DepartmentFilterDropdown: React.FC<Props> = ({
  maxHeight = 320,
  roleGroupIds,
  onChange,
}) => {
  const { roleGroups, loading } = useRoleGroups();
  const allChoices: Choice[] = useMemo(() => {
    return roleGroups.map((g) => ({
      label: g.name,
      value: g.id.toString(),
    }));
  }, [roleGroups]);
  const selectedChoices: Choice[] = useMemo(() => {
    return allChoices.filter((c) => roleGroupIds.includes(c.value));
  }, [allChoices, roleGroupIds]);
  const onSelectedChoicesChange = (newChoices: Choice[]): void => {
    onChange(newChoices.map((nc) => nc.value));
  };
  return (
    <MultiSelectMenuContent
      allChoices={allChoices}
      selectedChoices={selectedChoices}
      onSelectedChoicesChange={onSelectedChoicesChange}
      loading={loading}
      maxMenuHeight={maxHeight}
    />
  );
};

export default DepartmentFilterDropdown;
