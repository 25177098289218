import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import React from "react";

type Props = {
  count: number;
};

const ActiveFilterCount: React.FC<Props> = ({ count }) => {
  if (count === 0) return null;
  return (
    <div className={css(styles.countCircle)}>
      <div className={css(styles.countNumber)}>{count}</div>
    </div>
  );
};

const styles = StyleSheet.create({
  countCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    width: 16,
    height: 16,
    background: deprecatedTones.blue9,
    borderRadius: 20,
    marginRight: 6,
  },
  countNumber: {
    width: 8,
    height: 12,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: deprecatedTones.white,
    userSelect: "none",
  },
});

export default ActiveFilterCount;
