import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import React from "react";

type Props = {
  includeDeactivated: boolean | undefined | null;
  onChange: (includeDeactivated: boolean | undefined | null) => void;
};

export const IncludeDeactivatedFilterDropdown: React.FC<Props> = ({
  includeDeactivated,
  onChange,
}) => {
  return (
    <div className={css(styles.inputGroup)}>
      <RadioButton
        label="Yes"
        checked={includeDeactivated === true}
        onClick={() => {
          if (includeDeactivated === true) {
            onChange(undefined);
          } else {
            onChange(true);
          }
        }}
        styleDeclaration={styles.checkbox}
      />
      <RadioButton
        label="No"
        checked={includeDeactivated === false}
        onClick={() => {
          if (includeDeactivated === false) {
            onChange(undefined);
          } else {
            onChange(false);
          }
        }}
        styleDeclaration={styles.checkbox}
      />
      <div className={css(styles.clearSection)}>
        <div
          className={css(styles.clearText)}
          onClick={() => onChange(undefined)}
        >
          Clear
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  inputGroup: {
    margin: "8px 0px",
    maxHeight: 280,
    overflowY: "auto",
    backgroundColor: deprecatedColors.background,
  },
  clearSection: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  clearText: {
    paddingRight: 12,
    fontSize: 14,
    color: deprecatedTones.blue9,
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  checkbox: {
    fontSize: "30px",
    margin: "2px 10px 2px 8px",
    padding: "6px 12px 8px",
    borderRadius: "12px",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      cursor: "pointer",
    },
  },
});

export default IncludeDeactivatedFilterDropdown;
