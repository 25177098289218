import MultiSelectMenuContent, {
  Choice,
} from "@components/ui/multiSelect/MultiSelectMenuContent";
import useRoles from "@src/hooks/useRoles";
import React, { useMemo } from "react";

type Props = {
  maxHeight?: React.CSSProperties["maxHeight"];
  roleIds: number[];
  onChange: (roleIds: number[]) => void;
};

const RoleFilterDropdown: React.FC<Props> = ({
  maxHeight = 320,
  roleIds,
  onChange,
}) => {
  const { roles, loading } = useRoles();
  const allChoices: Choice[] = useMemo(() => {
    return roles.map((g) => ({
      label: g.name,
      value: g.id.toString(),
    }));
  }, [roles]);
  const selectedChoices: Choice[] = useMemo(() => {
    return allChoices.filter((c) => roleIds.includes(Number(c.value)));
  }, [allChoices, roleIds]);
  const onSelectedChoicesChange = (newChoices: Choice[]): void => {
    onChange(newChoices.map((nc) => Number(nc.value)));
  };
  return (
    <MultiSelectMenuContent
      allChoices={allChoices}
      selectedChoices={selectedChoices}
      onSelectedChoicesChange={onSelectedChoicesChange}
      loading={loading}
      maxMenuHeight={maxHeight}
    />
  );
};

export default RoleFilterDropdown;
