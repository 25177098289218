import * as React from "react";
import ChevronLeftIcon from "@src/ui/icons/12px/chevron-left";
import ChevronRightIcon from "@src/ui/icons/12px/chevron-right";
import { DayPicker } from "react-day-picker";

import { cn } from "@src/ui/lib/utils";
import { buttonVariants } from "@src/ui/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps): JSX.Element {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4 text-[14px] font-medium",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-[14px] focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-blue-2 [&:has([aria-selected].day-outside)]:bg-blue-1 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-[36px] w-[36px] p-0 font-normal aria-selected:opacity-100"
        ),
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-background hover:bg-primary-hover hover:text-background focus:bg-primary focus:text-background",
        day_today: "bg-blue-2 text-blue-13",
        day_outside:
          "day-outside text-muted-foreground opacity-50  aria-selected:bg-blue-1 aria-selected:text-gray-13 aria-selected:opacity-30",
        day_disabled: "text-gray-13 opacity-50",
        day_range_middle: "aria-selected:bg-blue-2 aria-selected:text-blue-13",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeftIcon />,
        IconRight: () => <ChevronRightIcon />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
