import Checkbox from "@src/deprecatedDesignSystem/components/Checkbox";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { UserType } from "@src/types.generated";
import { userTypeCopy } from "@src/utils/copy";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import React from "react";

type Props = {
  userTypes: UserType[];
  onChange: (userTypes: UserType[]) => void;
};

export const UserTypeFilterDropdown: React.FC<Props> = ({
  userTypes,
  onChange,
}) => {
  return (
    <>
      <div className={css(styles.inputGroup)}>
        <Checkbox
          label={userTypeCopy[UserType.Admin]}
          checked={userTypes.includes(UserType.Admin)}
          onChange={() => {
            if (userTypes.includes(UserType.Admin)) {
              onChange(userTypes.filter((type) => type !== UserType.Admin));
            } else {
              onChange([...userTypes, UserType.Admin]);
            }
          }}
          styleDeclaration={styles.checkbox}
        />
        <Checkbox
          label={userTypeCopy[UserType.Manager]}
          checked={userTypes.includes(UserType.Manager)}
          onChange={() => {
            if (userTypes.includes(UserType.Manager)) {
              onChange(userTypes.filter((type) => type !== UserType.Manager));
            } else {
              onChange([...userTypes, UserType.Manager]);
            }
          }}
          styleDeclaration={styles.checkbox}
        />
        <Checkbox
          label={userTypeCopy[UserType.Employee]}
          checked={userTypes.includes(UserType.Employee)}
          onChange={() => {
            if (userTypes.includes(UserType.Employee)) {
              onChange(userTypes.filter((type) => type !== UserType.Employee));
            } else {
              onChange([...userTypes, UserType.Employee]);
            }
          }}
          styleDeclaration={styles.checkbox}
        />
      </div>
      <div className={css(styles.clearSection)}>
        <div className={css(styles.clearText)} onClick={() => onChange([])}>
          Clear
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  inputGroup: {
    margin: "8px 0px",
    maxHeight: 280,
    overflowY: "auto",
    backgroundColor: deprecatedColors.background,
    width: 220,
  },
  clearSection: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  clearText: {
    paddingRight: 12,
    fontSize: 14,
    color: deprecatedTones.blue9,
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  checkbox: {
    fontSize: "30px",
    margin: "2px 10px 2px 8px",
    padding: "6px 12px 8px",
    borderRadius: "12px",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      cursor: "pointer",
    },
  },
});

export default UserTypeFilterDropdown;
