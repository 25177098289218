import MultiSelectMenuContent, {
  Choice,
} from "@components/ui/multiSelect/MultiSelectMenuContent";
import useLocationGroups from "@src/hooks/useLocationGroups";
import { LocationGroupType } from "@src/types.generated";
import React, { useMemo } from "react";

type Props = {
  maxHeight?: React.CSSProperties["maxHeight"];
  locationGroupIds: string[];
  onChange: (ids: string[]) => void;
  locationGroupType: LocationGroupType;
};

const LocationGroupFilterDropdown: React.FC<Props> = ({
  maxHeight = 320,
  locationGroupIds,
  onChange,
  locationGroupType,
}) => {
  const { lgTypeToLgs, loading } = useLocationGroups();
  const locationGroups = lgTypeToLgs[locationGroupType];
  const allChoices: Choice[] = useMemo(() => {
    return locationGroups.map((g) => ({
      label: g.name,
      value: g.id.toString(),
    }));
  }, [locationGroups]);
  const selectedChoices: Choice[] = useMemo(() => {
    return allChoices.filter((c) => locationGroupIds.includes(c.value));
  }, [allChoices, locationGroupIds]);
  const onSelectedChoicesChange = (newChoices: Choice[]): void => {
    const locationIds = newChoices.map((nc) => nc.value);
    onChange(locationIds);
  };
  return (
    <MultiSelectMenuContent
      allChoices={allChoices}
      selectedChoices={selectedChoices}
      onSelectedChoicesChange={onSelectedChoicesChange}
      loading={loading}
      maxMenuHeight={maxHeight}
    />
  );
};

export default LocationGroupFilterDropdown;
